import api from '@/js/services/axios'
import urlUtil from '@/js/utils/urlUtil'

const CDN_URL = process.env.VUE_APP_CDN_URL

export default {
  getHighlighted () {
    return new Promise((resolve, reject) => {
      api.get('public/v1/classes/highlighted')
        .then(response => {
          var data = response.data
          data.forEach(myClass => {
            this._processMyClass(myClass)
          });
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getByOrganization (organizationId) {
    return new Promise((resolve, reject) => {
      api.get('public/v1/organization/' + organizationId + '/classes')
        .then(response => {
          var data = response.data
          data.forEach(myClass => {
            this._processMyClass(myClass)
          });
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getById (id) {
    return new Promise((resolve, reject) => {
      api.get('public/v1/classes/' + id)
        .then(response => {
          var data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  findByIdentifier (organizationIdentifier, identifier) {
    var queryMap = new Map()
    queryMap.set('organization_identifier', organizationIdentifier)
    queryMap.set('identifier', identifier)
    var query = urlUtil.prepareQuery(queryMap)

    return new Promise((resolve, reject) => {
      api.get('public/v1/classes/find' + query)
        .then(response => {
          var data = response.data
          resolve(this._processMyClass(data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _processMyClass(myClass){
    if (!myClass){
      return null
    }

    //Image logic:
    //1. If cover is specified then use it.
    //  - If specify webp then also jpg should be - else use jpg
    //  - If specify _1920 then also _600 and _1200 should be - else just use the same
    //2. If cover is not specified then use organization identifier and class identifier to create the image url
    


    let identifier = myClass.identifier
    let organizationIdentifier = myClass.organization_lookup ? myClass.organization_lookup.identifier : null
    if (myClass.cover){
      let coverImageSrc = myClass.cover
      if (!coverImageSrc.startsWith('/img/') && CDN_URL){
        coverImageSrc = CDN_URL + coverImageSrc
      }
      myClass.src_image_600_webp = coverImageSrc.replace('_1920.', '_600.')
      myClass.src_image_1200_webp = coverImageSrc.replace('_1920.', '_1200.')
      myClass.src_image_1920_webp = coverImageSrc
      myClass.src_image_600_jpg = myClass.src_image_600_webp.replace('.webp', '.jpg')
      myClass.src_image_1200_jpg = myClass.src_image_1200_webp.replace('.webp', '.jpg')
      myClass.src_image_1920_jpg = myClass.src_image_1920_webp.replace('.webp', '.jpg')

    } else if (organizationIdentifier && identifier){
      let coverImageSrc = ''
      if (CDN_URL){
        coverImageSrc = CDN_URL
      }
      coverImageSrc += '/organization/' + organizationIdentifier + '/classes/' + identifier + '/' + identifier
      myClass.src_image_600_jpg = coverImageSrc + '_600.jpg'
      myClass.src_image_1200_jpg = coverImageSrc + '_1200.jpg'
      myClass.src_image_1920_jpg = coverImageSrc + '_1920.jpg'
      myClass.src_image_600_webp = coverImageSrc + '_600.webp'
      myClass.src_image_1200_webp = coverImageSrc + '_1200.webp'
      myClass.src_image_1920_webp = coverImageSrc + '_1920.webp'
    }

    return myClass
  }
}
