import i18n from '@/i18n'

export default {
  required: function (value) {
    if (typeof value === 'string' && value.trim() === '') {
      value = false
    }
    return !!value || i18n.t('common.form.validation.empty')
  },
  email: function (value) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || i18n.t('common.form.validation.invalid-email')
  },
  phone: function (value) {
    const pattern = /^([0-9\\(\\)\\/\\+ \\-]*)$/
    return pattern.test(value) || i18n.t('common.form.validation.invalid-phone')
  },
  maxImageSize: (value) => {
    let maxSizeInMb = 1
    let validationMessage = true
    if (value && value.size && value.size > (maxSizeInMb * 1000000)) {
      validationMessage = i18n.t('common.form.validation.max-image-size', { maxSize: maxSizeInMb })
    }
    return validationMessage;
  }
}
