export const LATEST_NEWS_VERSION = '2024/12';

const getDefaultState = () => {
  return {
    notifications: {
      lastNewsNotfication: null,
    },
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setNewsViewed (state, newsVersion) {
      state.notifications.lastNewsNotfication = newsVersion
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    viewNews ({ commit }, newsVersion) {
      commit('setNewsViewed', newsVersion)
    }
  },
  getters: {
    showNewsNotification: (state) => {
      return state.notifications.lastNewsNotfication !== LATEST_NEWS_VERSION;
    }
  }
}